import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@env/environment';
import { MenuSettings } from '@models/menu-settings/menu-settings';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MenuWebservice {
  readonly #http = inject(HttpClient);
  readonly #baseUrl = `${environment.api.obendy}/v1/partner-revision`;

  getSettings(partnerId: string): Observable<MenuSettings> {
    return this.#http.get<MenuSettings>(`${this.#baseUrl}/${partnerId}/menu-settings`);
  }
}
